import { Modal } from 'react-bootstrap';
import { Nft1 } from '../../../../../assets/images/images';
import { useAppDispatch } from '../../../../custom-hooks';
import { constants } from '../../../../../helpers';
import { onToggleModal } from '../../../../../redux/features/commonSlice';
import { calculateTimeDifference, getFormattedTimeDifference, truncateToDecimalDigits } from '../../../../../utils';

function Component({ isOpen, onClose, votersDetails }) {

    const dispatch = useAppDispatch();
    const votedTime = calculateTimeDifference(new Date(votersDetails.created))
    const handleVotesDetails = () => {
        dispatch(onToggleModal({ modalType: constants.MODAL.RECIEVED_DELEGATES_FROM }));
    }
    return (
        <Modal className='voter-info' show={isOpen} onHide={onClose} backdrop="static">
            <Modal.Header className='pb0' closeButton>
                <h6>{votersDetails?.headingType === "Collector" ? 'Collector Info' : 'Voter info'}</h6>
            </Modal.Header>

            <Modal.Body>
                <div className='voter-card'>
                    {/* <p className='text-text mr6'>#1</p> */}
                    <figure><img src={votersDetails?.user_detail?.profilePicture || Nft1} alt="icon" /></figure>
                    <div className='text'>
                        <p>{votersDetails?.user_detail?.userName}</p>
                        <p className='fs12 text-text'>{getFormattedTimeDifference(votedTime)}</p>
                    </div>

                    <div className='ms-auto text-end'>
                        <p className='text-purple lg txt'><i className='etherium-icon lg'></i>{votersDetails?.contribution ? truncateToDecimalDigits({
                            number: votersDetails.contribution,
                            limit: constants.COLLECTIVE.DECIMAL_LIMIT.DISPLAY
                        }) : '0'}</p>
                        <p className='text-text'>{votersDetails?.votingPercentage ? truncateToDecimalDigits({
                            number: votersDetails.votingPercentage,
                            limit: constants.COLLECTIVE.DECIMAL_LIMIT.PERCENTAGE
                        }) : '0'}%</p>
                    </div>
                </div>

                <div className='voter-card'>
                    <div className='text'>
                        <p className='lg txt'>Ownership</p>
                        <p className='fs12 text-text'>Self-delegated</p>
                    </div>

                    <div className='ms-auto text-end'>
                        <p className='lg'>{votersDetails?.selfVotingPercentage ? truncateToDecimalDigits({
                            number: votersDetails.selfVotingPercentage,
                            limit: constants.COLLECTIVE.DECIMAL_LIMIT.PERCENTAGE
                        }) : '0'}%</p>
                    </div>
                </div>

                <div className='voter-card'>
                    <div className='text'>
                        <p className='lg txt'>Current Voting Power</p>
                        <p className='fs12 text-text'>{votersDetails?.votingDelegationIds?.length > 1 ?
                            <span>Received delegate votes from <span role='button' className='d-voters'
                                onClick={() => handleVotesDetails()}>{votersDetails?.votingDelegationIds.length - 1} members</span></span>
                            : "No one has delegated to this member yet"}
                        </p>
                    </div>

                    <div className='ms-auto text-end'>
                        <p className='lg'>{votersDetails?.votingPercentage ? truncateToDecimalDigits({
                            number: votersDetails.votingPercentage,
                            limit: constants.COLLECTIVE.DECIMAL_LIMIT.PERCENTAGE
                        }) : '0'}%</p>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default Component;
