import { useAccount, usePublicClient, useWalletClient } from "wagmi";
import { messages } from "../../../helpers";
import { errorAlert } from "../../../utils";
import { ConnectWallet, FullPageLoader } from "../../components";
import { useAddCollective, useAppDispatch } from "../../custom-hooks";
import useCreateCollectionNFTCollective from "./useCreateCollectionNFTCollective";
import useCreateSingleNFTCollective from "./useCreateSingleNFTCollective";
import { walletTransactionRefershAlert } from "../../../redux/features/commonSlice";

function CreateCollective(props) {
  const {
    _nftContractAddress,
    isCollectiveType,
    isSubmitting,
    isDisabledSubmit,
    data,
    userId,
    resetForm,
    categories,
    setCategories,
    onIsSubmitting,
  } = props;

  const { data: walletClient } = useWalletClient();
  const { data: publicClient } = usePublicClient();

  const { address } = useAccount();
  const dispatch = useAppDispatch();


  // const buttonClassName = useMemo(() => {
  //   return addButtonLoader({ className: "btn lg", isLoading: isSubmitting });
  // }, [isSubmitting]);

  const buttonClassName = "btn lg";

  const { addCollectiveDetails, updateCollectiveDetails } = useAddCollective({
    data,
    userId,
    resetForm,
    categories,
    setCategories,
    onIsSubmitting,
  });

  const hookProps = {
    ...props,
    walletClient,
    publicClient,
    dispatch,
  };

  const { callCreateSingleNFTCrowdfund } =
    useCreateSingleNFTCollective(hookProps);
  const { callCreateCollectionNFTCrowdfund } =
    useCreateCollectionNFTCollective(hookProps);

  const callCreateCollection = isCollectiveType
    ? callCreateCollectionNFTCrowdfund
    : callCreateSingleNFTCrowdfund;

  if (!address) {
    return (
      <ConnectWallet
        type="create-collective"
        disabled={isDisabledSubmit}
        buttonClassName={buttonClassName}
      />
    );
  }

  return (
    <div className="text-center mt40">
      {!!isSubmitting && <FullPageLoader isHideBackground={false} />}
      <button
        className={buttonClassName}
        disabled={isDisabledSubmit}
        onClick={() => {
          if (!_nftContractAddress) {
            errorAlert(messages.COLLECTIVE.UNABLE_TO_RETRIEVE_FROM_OPENSEA);
            return false;
          }

          onIsSubmitting(true);

          addCollectiveDetails().then((response) => {
            if (
              response &&
              response.statusCode === 200 &&
              response.data?.collectiveId
            ) {
              // wallet transaction refersh alert
              dispatch(walletTransactionRefershAlert(false));
              callCreateCollection().then((hash) => {
                if (hash) {
                  updateCollectiveDetails({
                    collectiveDetails: response.data,
                    transactionHash: hash,
                  });
                }
              });
            }
          });
        }}
      >
        + Contribute and Launch
      </button>
    </div>
  );
}

export default CreateCollective;
