import { useAccount, usePublicClient, useWalletClient } from "wagmi";
import { ConnectWallet, FullPageLoader } from "../../components";
import { useAppDispatch, useUpdateVotingDelegation } from "../../custom-hooks";
import useDelegateVoteCollectionNFTEthCollective from "./useDelegateVoteCollectionNFTEthCollective";
import useDelegateVoteSingleNFTEthCollective from "./useDelegateVoteSingleNFTEthCollective";

function VotingDelegation(props) {
  const {
    isCollectionType,
    isDisabledSubmit,
    isSubmitting,
    votingDelegationId,
    resetForm,
    onIsSubmitting,
  } = props;

  const { data: walletClient } = useWalletClient();
  const { data: publicClient } = usePublicClient();

  const { address } = useAccount();
  const dispatch = useAppDispatch();

  // const buttonClassName = useMemo(() => {
  //   return addButtonLoader({
  //     className: "btn icon w-100",
  //     isLoading: isSubmitting,
  //   });
  // }, [isSubmitting]);

  const buttonClassName = "btn icon w-100";

  const { addUpdateVotingDelegation } = useUpdateVotingDelegation({
    votingDelegationId,
    resetForm,
    onIsSubmitting,
  });

  const hookProps = {
    ...props,
    walletClient,
    publicClient,
    dispatch
  };

  const { callUpdateOnlyDelegate: callUpdateOnlyDelegateCollectionNFT } =
    useDelegateVoteCollectionNFTEthCollective(hookProps);
  const { callUpdateOnlyDelegate: callUpdateOnlyDelegateSingleNFT } =
    useDelegateVoteSingleNFTEthCollective(hookProps);

  const callUpdateOnlyDelegate = isCollectionType
    ? callUpdateOnlyDelegateCollectionNFT
    : callUpdateOnlyDelegateSingleNFT;

  if (!address) {
    return (
      <ConnectWallet
        type="voting-delegation"
        disabled={isDisabledSubmit}
        buttonClassName={buttonClassName}
      />
    );
  }

  return (
    <>
      {!!isSubmitting && <FullPageLoader isHideBackground={false} />}
      <button
        className={buttonClassName}
        disabled={isDisabledSubmit}
        onClick={() => {
          onIsSubmitting(true);
          callUpdateOnlyDelegate().then((result) => {
            addUpdateVotingDelegation(result);
          });
        }}
      >
        Save Changes
      </button>
    </>
  );
}

export default VotingDelegation;
