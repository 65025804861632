import { useCallback } from "react";
import { useNetwork } from "wagmi";
import { postDataApi } from "../../api/methods";
import { constants, messages } from "../../helpers";
import { errorAlert } from "../../utils";
import { setRefreshVotingandCommentDetails, setRefreshCollectiveDetails, walletTransactionRefershAlert } from "../../redux/features/commonSlice";
import { useAppDispatch } from "./redux";
import config from "../../Config";

function useExecuteProposal({ _id, type, collectiveId, onIsSubmitting }) {
  const { chain } = useNetwork();
  const dispatch = useAppDispatch();

  const onExecuteProposal = useCallback(
    (transactionHash) => {
      try {
        if (!transactionHash) return false;

        // disabled save changes before trigger api call
        onIsSubmitting(true);

        const request = {
          proposalId: _id,
          network: config["NETWORK"],
          type: type,
          transactionHash,
          notify: true,
          callback: (response) => {
            if (response?.statusCode === 201) {

              // wallet transaction refersh alert
              dispatch(walletTransactionRefershAlert(false));

              // Trigger a refresh of collective details and collective Timeline
              var x = 0;
              var intervalID = setInterval(() => {
                // enabled save changes after api success
                onIsSubmitting(false);
                dispatch(setRefreshCollectiveDetails(true));
                dispatch(setRefreshVotingandCommentDetails(true));
                setTimeout(() => {
                  dispatch(setRefreshVotingandCommentDetails(false));
                  dispatch(setRefreshCollectiveDetails(false));
                }, 1000);

                // 2 time refresh then clear interval
                if (++x === 2) {
                  clearInterval(intervalID);
                }
              }, 10000);
            } else {
              console.log(response);
              // enabled save changes after api success
              onIsSubmitting(false);
            }

          },
        };

        postDataApi({
          path: constants.API_PATHS.EXECUTE_PROPOSAL,
          data: request,
        });
      } catch (error) {
        errorAlert(messages.COLLECTIVE.WALLET.BUY_NFT_FAILED);
        onIsSubmitting(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collectiveId, _id, type, chain, dispatch, setTimeout]
  );

  return { onExecuteProposal };
}

export default useExecuteProposal;
