/* eslint-disable no-unused-vars */
import { Suspense, useEffect, useMemo } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
	AllInOneModal,
	// ErrorBoundary
	Footer, Header, ScrollToTop
} from '../common/components';
import { useAppSelector } from '../common/custom-hooks';
import getRoutes from './routes';

function RoutesWrapper({ onForceReRender }) {
	const { auth: authReducer, common: commonReducer } = useAppSelector((state) => state);
	const routes = useMemo(() => getRoutes(authReducer.isloggedin), [authReducer.isloggedin]);

	useEffect(() => {
		// console.log(commonReducer.walletTransactionInprogress,"walletTransactionInprogress")
		if(commonReducer.walletTransactionInprogress){
			const handleBeforeUnload = (e) => {
				e.preventDefault();
				e.returnValue = 'Are you sure you want to leave this page?';
			  };
			
			  window.addEventListener('beforeunload', handleBeforeUnload);
			
			  return () => {
				window.removeEventListener('beforeunload', handleBeforeUnload);
			  };
			}
	  }, [commonReducer]);

	// Find the active route based on the current location
	// const activeRoute = useMemo(() => {
	// 	return routes.find((route) => route.path === window.location.pathname);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [window.location.pathname]);

	return (
		<Suspense>
			<BrowserRouter>
				{/* <ErrorBoundary> */}
				<>
					<Header
						// activeRoute={activeRoute}
						onForceReRender={onForceReRender}
					/>
					<ScrollToTop>
						<Routes>
							{routes.map((route) => {
								return <Route
									key={route.name}
									path={route.path}
									element={<route.Component onForceReRender={onForceReRender} />}
								/>;
							})}
						</Routes>
					</ScrollToTop>
					<AllInOneModal
						isOpen={!!(commonReducer.modal?.openedModalType)}
						type={commonReducer.modal?.openedModalType || ''}
						onForceReRender={onForceReRender}
					/>
					<Footer />
				</>
				{/* </ErrorBoundary> */}
			</BrowserRouter>
		</Suspense>
	);
}

export default RoutesWrapper;
