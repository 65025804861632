import { useCallback } from "react";
import { postDataApi } from "../../api/methods";
import { constants, messages } from "../../helpers";
import {
  setCollectiveDetails, walletTransactionRefershAlert,
  // setRefreshAllCollectorList,
  // setRefreshCollectiveDetails,
  // setRefreshVotingDelegationList,
} from "../../redux/features/commonSlice";
import { errorAlert } from "../../utils";
import { useAppDispatch } from "./redux";
import useCustomModals from "./useCustomModals";
import useReduxStore from "./useReduxStore";
import config from "../../Config";

/**
 * Custom hook for adding collective contribution.
 * @param {Object} params - Input parameters for the hook.
 * @param {Object} params.data - Data containing contribution details.
 * @param {Function} params.resetForm - Function to reset form values and errors.
 * @param {Function} params.onIsSubmitting - Function to control form submission state.
 * @param {string} params.claimCollectiveId - Collective ID for claiming.
 * @returns {Object} - An object with the `addCollectiveContribution` function.
 */
function useAddCollectiveContribution({
  data,
  resetForm,
  onIsSubmitting,
  claimCollectiveId,
}) {
  const { onOpenModal } = useCustomModals();
  const { commonReducer } = useReduxStore();
  const dispatch = useAppDispatch();
  const shareLink = `${config.WEB_URL}${constants.BROWSER_ROUTES.VIEW_COLLECTIVE
    }?collectiveId=${claimCollectiveId || ""}`;

  /**
   * Function to add collective contribution.
   * @param {string} transactionHash - Transaction hash from the blockchain.
   */
  const addCollectiveContribution = useCallback(
    (transactionHash) => {
      try {
        if (!transactionHash) return false;

        // Check if the modal contains the required collectiveId
        if (!commonReducer.modal?.collective?.collectiveId) {
          return false;
        }

        // Disable "Save Changes" button before triggering the API call
        onIsSubmitting(true);

        const request = {
          contribution: data.contribute,
          // "address": "string",
          // "gasCredit": 0,
          transactionHash,
          notify: true,
          callback: (response) => {
            if (response?.statusCode === 200) {
              if (!claimCollectiveId) {
                resetForm();
              }

              // wallet transaction refersh alert
              dispatch(walletTransactionRefershAlert(false))

              // Update the Redux store with new contribution details
              dispatch(
                setCollectiveDetails({
                  contribute: data.contribute,
                  collectiveId:
                    claimCollectiveId ||
                    commonReducer.modal.collective.collectiveId,
                  isClaimedContribute: true,
                })
              );
              // Open a success modal
              onOpenModal(constants.MODAL.CONTRIBUTION_SUCCESS);
              if (claimCollectiveId) {
                window.open(shareLink, "_blank", "noreferrer");
              }

              // // Trigger a refresh of collective details
              // setTimeout(() => {
              //   dispatch(setRefreshCollectiveDetails(true));
              //   dispatch(setRefreshVotingDelegationList(true));
              //   dispatch(setRefreshAllCollectorList(true));

              //   setTimeout(() => {
              //     dispatch(setRefreshCollectiveDetails(false));
              //     dispatch(setRefreshVotingDelegationList(false));
              //     dispatch(setRefreshAllCollectorList(false));
              //   }, 1000);

              //   // Trigger a refresh of collective details
              //   setTimeout(() => {
              //     dispatch(setRefreshCollectiveDetails(true));
              //     dispatch(setRefreshVotingDelegationList(true));
              //     dispatch(setRefreshAllCollectorList(true));

              //     setTimeout(() => {
              //       dispatch(setRefreshCollectiveDetails(false));
              //       dispatch(setRefreshVotingDelegationList(false));
              //       dispatch(setRefreshAllCollectorList(false));
              //     }, 1000);
              //   }, 10000);
              // }, 10000);
            }

            // Enable "Save Changes" button after API success
            onIsSubmitting(false);
          },
        };

        // Set optional properties in the request
        if (data.selectedUser?.votingDelegationId) {
          request.votingDelegationId = data.selectedUser.votingDelegationId;
        }
        if (claimCollectiveId) {
          request.collectiveId = claimCollectiveId;
          request.claimCollectiveId =
            commonReducer.modal.collective.collectiveId;
        } else {
          request.collectiveId = commonReducer.modal.collective.collectiveId;
        }

        // Make the API call to contribute to the collective
        postDataApi({
          path: constants.API_PATHS.USER_CONTRIBUTE_COLLECTIVE,
          data: request,
        });
      } catch (error) {
        errorAlert(messages.COLLECTIVE.WALLET.COLLECTIVE_CONTRIBUTION_FAILED);
      }
    },
    [
      data,
      resetForm,
      onIsSubmitting,
      commonReducer,
      claimCollectiveId,
      dispatch,
      onOpenModal,
      shareLink,
    ]
  );

  return { addCollectiveContribution };
}

export default useAddCollectiveContribution;
