import { useCallback } from "react";
import config from "../../Config";
import { postDataApi } from "../../api/methods";
import { constants, messages } from "../../helpers";
import { setCollectiveDetails, walletTransactionRefershAlert } from "../../redux/features/commonSlice";
import { capitalizeFirstLetter, errorAlert, successAlert } from "../../utils";
import { useAppDispatch } from "./redux";
import useCustomModals from "./useCustomModals";

/**
 * Custom hook for adding collective details.
 * @param {Object} params - Input parameters for the hook.
 * @param {Object} params.data - Data containing collective details.
 * @param {string} params.userId - User ID.
 * @param {Function} params.resetForm - Function to reset form values and errors.
 * @param {Array} params.categories - List of categories.
 * @param {Function} params.setCategories - Function to set categories.
 * @param {Function} params.onIsSubmitting - Function to control form submission state.
 * @returns {Object} - An object with the `addCollectiveDetails` function.
 */
function useAddCollective({
  data,
  userId,
  resetForm,
  categories,
  setCategories,
  onIsSubmitting,
}) {
  const dispatch = useAppDispatch();
  const { onOpenModal } = useCustomModals();

  /**
   * Function to add collective details.
   * @param {string} transactionHash - Transaction hash from the blockchain.
   */
  const addCollectiveDetails = useCallback(() => {
    try {
      const request = {
        userId,
        collectiveType: data.collectiveType,
        url: data.url,
        title: data.title,
        subTitle: data.subTitle,
        contributionGoal: {
          amount: data.contributionGoal,
          type: constants.COLLECTIVE.PRICE.SYMBOL.ETH,
        },
        contributionAmount: {
          amount: data.contributionAmount,
          type: constants.COLLECTIVE.PRICE.SYMBOL.ETH,
        },
        contributionPeriod: String(data.contributionPeriod),
        proposal: [data.proposal],
        governance: {
          acceptanceThreshold: data.acceptanceThreshold,
          vetoPeriod: String(data.vetoPeriod),
          proposalExpiration: String(data.proposalExpiration),
        },
        utilityAvailable: data.utilityAvailable,
        platform: data.platform,
        network: config["NETWORK"],
      };
      if (data.details.name) {
        request.name = data.details.name;
      }
      if (data.details.image) {
        request.featured_image_url = data.details.image;
      }
      if (data.details.profile_image) {
        request.image = data.details.profile_image;
      }
      if (categories.length) {
        const temp = categories
          .filter((cat) => cat.isChecked)
          .map((cat) => {
            return {
              category: cat.name,
              subCategory: cat.subCategoryData
                .filter((scat) => scat.isChecked)
                .map((scat) => scat.name),
            };
          });
        request.nftCategory = temp;
      }
      if (data.discordId) {
        request.discordId = data.discordId;
      }
      if (data.anticipatedApy) {
        request.anticipatedApy = String(data.anticipatedApy);
      }
      if (data.sellingDate) {
        request.sellingDate = data.sellingDate;
      }
      if (data.sellingPrice) {
        request.sellingPrice = {
          amount: parseFloat(data.sellingPrice),
          type: constants.COLLECTIVE.PRICE.SYMBOL.ETH,
        };
      }
      if (data.websiteUrl) {
        request.websiteUrl = data.websiteUrl;
      }
      return postDataApi({
        path: constants.API_PATHS.ADD_COLLECTIVE,
        data: request,
      });
    } catch (error) {
      errorAlert(messages.COLLECTIVE.WALLET.CREATE_COLLECTIVE_FAILED);
      // wallet transaction refersh alert
      dispatch(walletTransactionRefershAlert(false));
    }
  }, [data, userId, categories, dispatch]);

  /**
   * Function to update collective details.
   */
  const updateCollectiveDetails = useCallback(
    (details) => {
      try {
        const request = {
          userId,
          collectiveId: details.collectiveDetails.collectiveId,
          contribute: {
            amount: parseFloat(data.contribute),
            type: constants.COLLECTIVE.PRICE.SYMBOL.ETH,
          },
          transactionHash: details.transactionHash,
          network: config["NETWORK"],
          callback: (response) => {
            if (response?.statusCode === 200) {
              successAlert(capitalizeFirstLetter(response.message));
              // wallet transaction refersh alert
              dispatch(walletTransactionRefershAlert(false));
              // Dispatch an action to update the Redux store
              dispatch(
                setCollectiveDetails({
                  collectiveId: details.collectiveDetails.collectiveId,
                  contribute: data.contribute,
                })
              );
              // Open a success modal
              onOpenModal(constants.MODAL.COLLECTIVE_LAUNCH_SUCCESS);

              // Reset the form values and errors
              resetForm();

              // Reset initial NFT categories
              const temp = categories.slice();
              for (let cat of temp) {
                cat.isChecked = false;
                for (let scat of cat.subCategoryData) {
                  scat.isChecked = false;
                }
              }
              setCategories(temp);
            } else if (response?.data?.message) {
              errorAlert(capitalizeFirstLetter(response.data.message));
            }

            // Enable "Save Changes" button after API success
            onIsSubmitting(false);
          },
        };
        postDataApi({
          path: constants.API_PATHS.UPDATE_COLLECTIVE,
          data: request,
        });
      } catch (error) {
        errorAlert(messages.COLLECTIVE.WALLET.CREATE_COLLECTIVE_FAILED);
        // wallet transaction refersh alert
        dispatch(walletTransactionRefershAlert(false));
      }
    },
    [
      data,
      userId,
      resetForm,
      categories,
      dispatch,
      setCategories,
      onIsSubmitting,
      onOpenModal,
    ]
  );

  return { addCollectiveDetails, updateCollectiveDetails };
}

export default useAddCollective;
