import { Modal } from 'react-bootstrap';
import { Nft1 } from '../../../../../assets/images/images';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { onToggleModal, setProposalDetails, setVoterInfo } from '../../../../../redux/features/commonSlice';
import { constants } from '../../../../../helpers';
import { useAppDispatch } from '../../../../custom-hooks';
import { useState } from 'react';
import { calculateTimeDifference, getFormattedTimeDifference, truncateToDecimalDigits } from '../../../../../utils';

function Component({ isOpen, onClose, commentList, onLodeMore, currentPage, votingList, onLoadVotes, currentPageVoting, commonReducer }) {
   
    const dispatch = useAppDispatch();
    const [isExpanded, setIsExpanded] = useState(false);
    const [selectedId, setSelectedId] = useState('')
    const disableAddVote = commonReducer.modal.proposal?.isUserVoted !== 0 || commonReducer.modal.proposal?.votingCloseTime === 0 || commonReducer.modal.proposal?.votingDetails?.totalYes >= 100
    
    const addCommentandVote = (type) => {
        dispatch(setProposalDetails({ proposalId: commonReducer.modal.proposal?.proposalId, type: commonReducer.modal.proposal?.type }));
        if (type === "add-comment") {
            dispatch(onToggleModal({ modalType: constants.MODAL.ADD_COMMENT }));
        } else {
            dispatch(onToggleModal({ modalType: constants.MODAL.ADD_VOTE }));
        }
    };

    const viewVoterInfo=(info)=>{
      
        dispatch(setVoterInfo(info))
        dispatch(onToggleModal({ modalType: constants.MODAL.VOTERS_INFO }))
    }

    const toggleReadMore = (id) => {
        setSelectedId(id)
        if (selectedId === id) {
            setIsExpanded(!isExpanded);
        } else {
            setIsExpanded(true);
        }
    };

    return (
        <Modal className='comments-voters' show={isOpen} onHide={onClose} backdrop="static">
            <Modal.Header className='pb0' closeButton>
                <h6 className='f20 mb0'>Proposal Details</h6>
            </Modal.Header>

            <Modal.Body>
                <div className='tabs'>
                    <Tabs defaultActiveKey={commonReducer.modal.proposal?.tabKey}>
                        <Tab eventKey="tabComments" title={`Comments (${commentList?.totalCount})`}>
                            <div className='all-comnts'>
                                {commentList?.list?.length > 0 && commentList.list.map((data) => {
                                    const time = calculateTimeDifference(new Date(data.created));
                                    return(
                                    <div key={data._id} className='vote-comment'>
                                        <figure><img src={data?.user_detail?.profilePicture || Nft1} alt="icon" /></figure>
                                        <div className='text'>
                                            <p className='lg'>{data?.user_detail?.userName}</p>
                                            <p className='fs12'>{(isExpanded && data._id === selectedId) ? data?.comment : data?.comment.slice(0, 100)}</p>
                                            {data?.comment.length > 100 &&
                                                <span role='button' className='link fs12' onClick={() => toggleReadMore(data._id)}>{(isExpanded && data._id === selectedId) ? 'Read Less' : 'Read More'}</span>
                                            }
                                        </div>

                                        <div className='ml-auto flex-shrink-0'>
                                            <p className='fs12 text-blue fw600'>{getFormattedTimeDifference(time)}</p>
                                        </div>
                                    </div>
                                )}
                                )}
                                {commentList?.list.length === 0 &&
                                    <div className='ml-auto d-flex align-items-baseline flex-shrink-0'>
                                        <p className='lg mr12'>No Comments Found.</p>
                                    </div>
                                }
                                {((commentList.total !== currentPage) && commentList?.list?.length > 0) &&
                                    <div className='d-flex justify-content-center'>
                                        <p role='button' className='link' onClick={onLodeMore}>View More</p>
                                    </div>
                                }
                            </div>
                            {(commonReducer.modal.proposal?.status === "INPROGRESS" || commonReducer.modal.proposal?.status === "ACTIVE") &&
                                <div className=' text-center pt24'>
                                    <button className='btn lg w-200' onClick={() => addCommentandVote('add-comment')} >Add Comment</button>
                                </div>
                            }
                        </Tab>

                        <Tab eventKey="tabVotes" title={`Voters (${votingList?.totalCount})`} >
                            {votingList?.list?.length > 0 && votingList.list.map((data) => {
                                const votetime = calculateTimeDifference(new Date(data.created));
                                return(
                                <div key={data._id} className='vote-comment' style={{cursor:"pointer"}} onClick={() => viewVoterInfo(data)}>
                                    <figure><img src={data?.user_detail?.profilePicture || Nft1} alt="icon" /></figure>
                                    <div className='text'>
                                        <p className='lg'>{data?.user_detail?.userName}</p>
                                        <p className='fs12'>{data?.votingDelegationIds?.length>1? `Self, ${data?.votingDelegationIds.length-1} Voters delegations`:"Self Delegation"} </p>
                                    </div>

                                    <div className='ml-auto d-flex align-items-baseline flex-shrink-0'>
                                       <p className='lg text-purple fw600'>{data?.vote === "YES" ? "Yes" :data?.vote === "NO" ? "No" :"Abstain"}</p>
                                        <p className='lg'>{data?.votingPercentage ? truncateToDecimalDigits({ number: data?.votingPercentage, limit: constants.COLLECTIVE.DECIMAL_LIMIT.PERCENTAGE }):'0'}% of votes</p>
                                        <p className=' text-blue fw600'>{getFormattedTimeDifference(votetime)}</p>
                                    </div>
                                </div>
                            )}
                            )}

                            {votingList?.list.length === 0 &&
                                <div className='ml-auto d-flex align-items-baseline flex-shrink-0'>
                                    <p className='lg mr12'>No Votes Found.</p>
                                </div>
                            }

                            {((votingList.total !== currentPageVoting) && votingList?.list.length > 0) &&
                                <div className='d-flex justify-content-center'>
                                    <p role='button' onClick={onLoadVotes}>View More</p>
                                </div>
                            }
                            {(commonReducer.modal.proposal?.status === "INPROGRESS" || commonReducer.modal.proposal?.status === "ACTIVE") &&
                                <div className=' text-center pt24'>
                                    <button
                                        type='button'
                                        className='btn lg w-200'
                                        onClick={() => addCommentandVote('add-vote')}
                                        disabled={disableAddVote}
                                    >
                                        Add Your Vote
                                    </button>
                                </div>
                            }
                        </Tab>
                    </Tabs>
                </div>

            </Modal.Body>
        </Modal>
    );
}

export default Component;
