"use client";
import messages from "../../../helpers/messages.js";
import { walletTransactionRefershAlert } from "../../../redux/features/commonSlice/index.js";
import { errorAlert } from "../../../utils/alert.js";
import { singleNFTCrowdfundAbi } from "../scripts/index.js";

function useContributeInSingleNFTEthCollective({
  collectiveCrowdfundAddress,
  delegateAddress,
  amount,
  walletClient,
  onIsSubmitting,
  dispatch,
}) {
  const callContribute = async () => {
    try {
      dispatch(walletTransactionRefershAlert(true))
      const claimedTxHash = await walletClient.writeContract({
        address: collectiveCrowdfundAddress,
        abi: singleNFTCrowdfundAbi,
        functionName: "contribute",
        args: [delegateAddress],
        // chainId: goerli.id,
        // gas: 6000000,
        value: amount,
        onSettled(data, error) {
          console.log("Settled", { data, error });
        },
      });
      console.log("claimedTxHash", claimedTxHash);
      return claimedTxHash;

      // let reciept = await publicClient.waitForTransactionReceipt({
      //     confirmation: 2,
      //     hash: claimedTxHash,
      // })
      // console.log("reciept", reciept);
      // const [accAddress] = await walletClient.getAddresses();
      // let connectedAddress = accAddress;
      // const { ethContributed } = await publicClient.readContract({
      //     address: collectiveCrowdfundAddress,
      //     abi: singleNFTCrowdfundAbi,
      //     functionName: "getContributorInfo",
      //     args: [connectedAddress],
      // })
      // console.log("ethContributed", ethContributed);
    } catch (error) {
      console.log("callContribute-error", error);
      onIsSubmitting(false);
      errorAlert(messages.COLLECTIVE.WALLET.TRANSACTION_DENIED);
      // wallet transaction refersh alert
      dispatch(walletTransactionRefershAlert(false));
    }
  };

  return { callContribute };
}

export default useContributeInSingleNFTEthCollective;
