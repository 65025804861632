
const FullPageLoader = ({ isHideBackground }) => {
    return (
        <div className={`loader-full ${isHideBackground ? 'white' : ''}`}>
            <span></span>
        </div>
    );
};

FullPageLoader.defaultProps = {
    isHideBackground: true
};

export default FullPageLoader;
