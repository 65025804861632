import { useCallback } from "react";
import { useNetwork } from "wagmi";
import { postDataApi } from "../../api/methods";
import { constants, messages } from "../../helpers";
import { errorAlert } from "../../utils";
import { useAppNavigate } from "./route";
import { useAppDispatch } from "./redux";
import {
  onToggleModal,
  setRefreshCollectiveDetails,
  setRefreshDashbordDetails,
  walletTransactionRefershAlert,
} from "../../redux/features/commonSlice";

function useClaimEth({ collectiveId, claimAmount, setIsSubmitting }) {
  const dispatch = useAppDispatch();
  const { chain } = useNetwork();
  const navigate = useAppNavigate();

  const onClaim = useCallback(
    (transactionHash) => {
      try {
        if (!transactionHash) return false;

        // disabled save changes before trigger api call
        setIsSubmitting(true);
        
        const request = {
          // collectiveId:collectiveId,
          claimAmount: claimAmount,
          transactionHash: transactionHash,
          notify: true,
          callback: (response) => {
            if (response?.statusCode === 201) {
              var y = 0;
              var intervalIDDashboard = setInterval(() => {
                // dashbord claim earnings refresh page
                dispatch(setRefreshDashbordDetails(true));
                // wallet transaction refersh alert
                dispatch(walletTransactionRefershAlert(false))
                setTimeout(() => {
                  dispatch(setRefreshDashbordDetails(false));
                }, 1000);

                // 2 time refresh then clear interval
                if (++y === 2) {
                  clearInterval(intervalIDDashboard);
                }
              }, 10000);

              if (collectiveId) {
                dispatch(onToggleModal());

                var x = 0;
                var intervalID = setInterval(() => {
                  // Your logic here
                  dispatch(setRefreshCollectiveDetails(true));
                  setTimeout(() => {
                    dispatch(setRefreshCollectiveDetails(false));
                  }, 1000);

                  // 2 time refresh then clear interval
                  if (++x === 2) {
                    clearInterval(intervalID);
                  }
                }, 10000);
              }
            } else {
              console.log(response, "ERROR");
            }

            // enabled save changes after api success
            setIsSubmitting(false);
          },
        };
        if (collectiveId) {
          request["collectiveId"] = collectiveId;
        }
        postDataApi({
          path: constants.API_PATHS.CLAIM_AMOUNT,
          data: request,
        });
      } catch (error) {
        errorAlert(messages.COLLECTIVE.WALLET.TRANSACTION_DENIED);
        setIsSubmitting(false);
        // wallet transaction refersh alert
        dispatch(walletTransactionRefershAlert(false));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collectiveId, claimAmount, chain, navigate, dispatch]
  );

  return { onClaim };
}

export default useClaimEth;
