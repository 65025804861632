import axios from "axios";
import React, { useEffect, useState } from "react";
import { socialLogin } from "../../../redux/services/auth";
import { errorAlert, successAlert } from "../../../utils";
import { useAppDispatch, useReduxStore } from "../../custom-hooks";
import config from "../../../Config";

const DiscordLoginComponent = ({ type }) => {
  const dispatch = useAppDispatch();
  const { authReducer } = useReduxStore();
  const [token, setToken] = useState();
  const [discordUser, setDiscordUser] = useState();

  const handleDiscordLogin = () => {
    const redirectUri = encodeURIComponent(config.DISCORD_REDIRECT_URI);
    window.location = `https://discord.com/api/oauth2/authorize?client_id=${config.DISCORD_CLIENT_ID}&redirect_uri=${redirectUri}&response_type=code&scope=identify%20email`;
  };
  useEffect(() => {
    const fetchAccessToken = async () => {
      const code = new URLSearchParams(window.location.search).get("code");
      if (code) {
        const response = await axios.post(
          config.DISCORD_AUTH_TOKEN,
          {
            client_id: config.DISCORD_CLIENT_ID,
            client_secret: config.DISCORD_CLIENT_SECRET,
            grant_type: config.DISCORD_GRANT_TYPE,
            code,
            redirect_uri: config.DISCORD_REDIRECT_URI,
            scope: "identify email",
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        const accessToken = response.data.access_token;
        setToken(accessToken);
      }

      // Use the access token as needed (e.g., store it in local storage or state)
    };

    if (!authReducer.isDiscordLogin) {
      fetchAccessToken();
    }


  }, [authReducer.isDiscordLogin]);


  useEffect(() => {
    const fetchUserDetail = async () => {
      if (token) {
        try {
          const response = await axios.get(
            config.DISCORD_USER_API,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const userDetails = response.data;
          setDiscordUser(userDetails);

          // Use the user details as needed
        } catch (error) {
          // Handle any errors that occur during the API request
          errorAlert("Something went wrong!");
        }
      }
    };
    fetchUserDetail();
  }, [token]);
  useEffect(() => {
    if (discordUser) {
      const discriminator = discordUser?.discriminator
      const userName = `${discordUser?.username}${discriminator !== '0' ? '#' + discordUser?.discriminator : ''}`
      const request = {
        email: discordUser.email || "",
        firstName: userName || "",
        // lastName: discordUser.username.split(" ")[1] || "",
        profileUrl: config.DISCORD_USER_PROFILE_API,
        socialLoginType: "discord",
        socialId: discordUser.id,
        // deviceId: deviceId,
        deviceToken: "string",
        notify: false,
        callback: (response) => {
          if (response?.statusCode === 200) {
            if (response.message) {
              successAlert("Discord Connected Successfully");
            } else if (response?.data?.message) {
            }
          }
        },
      };
      dispatch(socialLogin(request));
    }
  }, [dispatch, discordUser]);



  return type === "icon" ? (
    <span onClick={handleDiscordLogin} className={"inactive"}>
      <div className='tooltip-div'>
        <i className="discord-lg-icon cursor-pointer trig"></i>
        <p className='text xs fw500'>Discord is not connected</p>
      </div>
    </span>
  ) : (
    <span
      className="link ml-auto align-self-center fs15 cursor-pointer"
      onClick={handleDiscordLogin}
    >
      <u>Connect Now</u>
    </span>
  );
};

export default DiscordLoginComponent;
