"use client";
import messages from "../../../helpers/messages.js";
import { walletTransactionRefershAlert } from "../../../redux/features/commonSlice/index.js";
import { errorAlert } from "../../../utils/alert.js";
import { singleNFTCrowdfundAbi } from "../scripts/index.js";

function useDelegateVoteSingleNFTEthCollective({
  collectiveCrowdfundAddress,
  delegateAddress,
  walletClient,
  onIsSubmitting,
  dispatch
}) {
  const callUpdateOnlyDelegate = async () => {
    try {
      dispatch(walletTransactionRefershAlert(true))
      const updateDelegateTxHash = await walletClient.writeContract({
        address: collectiveCrowdfundAddress,
        abi: singleNFTCrowdfundAbi,
        functionName: "updateOnlyDelegate",
        args: [delegateAddress],
        // chainId: goerli.id,
        // gas: 6000000,
        onSettled(data, error) {
          console.log("Settled", { data, error });
        },
      });
      console.log("updateDelegateTxHash", updateDelegateTxHash);
      return updateDelegateTxHash;

      // let reciept = await publicClient.waitForTransactionReceipt({
      //   confirmation: 2,
      //   hash: updateDelegateTxHash,
      // });
      // console.log("reciept", reciept);
      // const [accAddress] = await walletClient.getAddresses();
      // let connectedAddress = accAddress;

      // const newDelegate = await publicClient.readContract({
      //   address: collectiveCrowdfundAddress,
      //   abi: singleNFTCrowdfundAbi,
      //   functionName: "delegationsByContributor",
      //   args: [connectedAddress],
      // });
      // console.log("newDelegate", newDelegate);
    } catch (error) {
      console.log("callUpdateOnlyDelegate-error", error);
      onIsSubmitting(false);
      errorAlert(messages.COLLECTIVE.WALLET.TRANSACTION_DENIED);
      // wallet transaction refersh alert
      dispatch(walletTransactionRefershAlert(false));
    }
  };

  return { callUpdateOnlyDelegate };
}

export default useDelegateVoteSingleNFTEthCollective;
