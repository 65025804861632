"use client";
import { useAccount, useWalletClient } from "wagmi";
import { collectionNFTCrowdfundAbi } from "../scripts/index.js";
import { errorAlert } from "../../../utils/alert.js";
import messages from "../../../helpers/messages.js";
import { ConnectWallet } from "../../components/index.js";
import { useAppDispatch, useClaimEth } from "../../custom-hooks/index.js";
import { useCallback, useState } from "react";
import FullPageLoader from "../../components/full-page-loader/index.jsx";
import { walletTransactionRefershAlert } from "../../../redux/features/commonSlice/index.js";

const buttonConfig = {
  className: "btn",
  title: "Connect Wallet",
};

function CliamEth({ details }) {
  const { data: walletClient } = useWalletClient();
  const [isDisabledSubmit, setIsSubmitting] = useState(false);
  const { address } = useAccount();
  const dispatch = useAppDispatch();

  const { onClaim } = useClaimEth({
    collectiveId: details.collectiveId,
    claimAmount: {
      amount: details?.goal?.myClaim,
      type: details?.goal?.goal?.type,
    },
    setIsSubmitting: setIsSubmitting,
  });

  const callCliam = useCallback(async () => {
   
    try {
      dispatch(walletTransactionRefershAlert(true))
      const txHash = await walletClient.writeContract({
        address: details.contractAddress,
        abi: collectionNFTCrowdfundAbi,
        functionName: "claimUnusedContribution",
        args: [address],
        // chainId: goerli.id,
        // gas: 6000000,
        onSettled(data, error) {
          console.log("Settled", { data, error });
        },
      });
      return txHash;
    } catch (error) {
      console.log("callExecute-error", error);
      setIsSubmitting(false);
      errorAlert(messages.COLLECTIVE.WALLET.TRANSACTION_DENIED);
      // wallet transaction refersh alert
      dispatch(walletTransactionRefershAlert(false));
    }
  }, [walletClient, details, address, setIsSubmitting, dispatch]);

  if (!address) {
    return (
      <ConnectWallet
        type="buy-nft"
        disabled={isDisabledSubmit}
        buttonConfig={buttonConfig}
      />
    );
  }


  return (
    <>
      {isDisabledSubmit && <FullPageLoader isHideBackground={false} />}
      <button
        className={isDisabledSubmit ? "link disabled" : "link"}
        disabled={isDisabledSubmit}
        onClick={() => {
          setIsSubmitting(true);
          callCliam().then((result) => {
            onClaim(result);
          });
        }}
      >
        Claim ETH
      </button>
    </>
  );
}

export default CliamEth;
