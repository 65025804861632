import { useCallback } from "react";
import config from "../../Config";
import { postDataApi } from "../../api/methods";
import { constants, messages } from "../../helpers";
import {
  setBuyNftProgressLoading, walletTransactionRefershAlert,
} from "../../redux/features/commonSlice";
import { errorAlert } from "../../utils";
import { useAppDispatch } from "./redux";
import { useAppNavigate } from "./route";

/**
 * Custom hook for buying NFT.
 * @param {Object} params - Input parameters for the hook.
 * @param {Object} params.data - Data containing NFT details.
 * @param {Function} params.onIsSubmitting - Function to control form submission state.
 * @returns {Object} - An object with the `onBuyNft` function.
 */
function useBuyNft({ data, onIsSubmitting }) {
  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();

  /**
   * Function to perform NFT purchase.
   * @param {string} transactionHash - Transaction hash from the blockchain.
   */
  const onBuyNft = useCallback(
    (transactionHash) => {
      try {
        if (!transactionHash) return false;

        // Disable "Save Changes" button before triggering the API call
        onIsSubmitting(true);

        const request = {
          collectiveId: data.collectiveId || "",
          contractAddress: data.contractAddress || "",
          tokenId: data.tokenId || "",
          network: config["NETWORK"],
          transactionHash,
          name: data.name || "",
          image: data.image || "",
          amount: data.amount || 0,
          notify: true,
          callback: (response) => {
            if (response?.statusCode === 200) {
              dispatch(setBuyNftProgressLoading(true));

               // wallet transaction refersh alert
               dispatch(walletTransactionRefershAlert(false))

              if (
                window.location.pathname.includes(
                  constants.BROWSER_ROUTES.BUY_NFT
                )
              ) {
                // Redirect to view collective page after successful purchase
                navigate(
                  `${constants.BROWSER_ROUTES.VIEW_COLLECTIVE}?collectiveId=${
                    data.collectiveId || ""
                  }`
                );
              }
            }

            // Enable "Save Changes" button after API success
            onIsSubmitting(false);
          },
        };

        // Make the API call to buy the NFT
        postDataApi({
          path: constants.API_PATHS.BUY_NFT,
          data: request,
        });
      } catch (error) {
        errorAlert(messages.COLLECTIVE.WALLET.BUY_NFT_FAILED);
      }
    },
    [data, navigate, dispatch, onIsSubmitting]
  );

  return { onBuyNft };
}

export default useBuyNft;
